<template>
    <button
        class="marquage"
        :class="{ 'is-active': slug === selectedSlug, 'has-items': hasItems }"
        @click.prevent="$emit('selected', slug)"
    >
        <span class="marquage-icon">
            <img v-if="currentTechnique" :src="currentTechnique.urlIcon.replace('web-tab', 'localhost:8000')" alt="">
        </span>
        <span class="marquage-text">{{ marquage.label }}</span>
<!--        <span class="marquage-technique" v-if="marquage.config.markingTypeLabel">{{marquage.config.markingTypeLabel}}</span>-->
        <span class="marquage-color" v-if="marquage.config.markingTypeVariantLabel">
            {{marquage.config.markingTypeVariantLabel}}
        </span>
        <span class="marquage-edit">
            <svg-sprite width="13" height="13" icon="modifier" />
            <span>modifier</span>
        </span>
    </button>
</template>

<script>
    export default {
        name: 'SidebarMarquageItem',
        props: {
            marquage: Object,
            slug: String,
            selectedSlug: String,
        },
        computed: {
            currentTechnique() {
                return this.$store.getters['marquage/currentTechnique'](this.marquage.id)
            },
            hasItems() {
                const items = this.$store.getters['editor/itemsByMarquage']({
                    marquage: this.marquage.id
                })
                return Object.keys(items).length > 0
            }
        }
    }
</script>
