<template>
    <div class="variant-spot" :class="{ 'is-selected': selected, 'is-small': small, 'has-text-left': textLeft, 'is-dark': darkMode, 'is-disabled': disabled }" @mouseover="onMouseOver">
        <div class="variant-spot-color" :style="colorStyle">
            <span v-if="quantity">{{ quantity }}</span>
        </div>
        <div class="variant-spot-text">
            {{ option.label }}
        </div>
    </div>
</template>

<script>
    import colorsTransform from "../../utils/colors";

    export default {
        name: 'VariantSpot',
        data: () => ({
            textLeft: false
        }),
        props: {
            option: Object,
            selected: Boolean,
            small: Boolean,
            quantity: Number,
            disabled: Boolean
        },
        computed: {
            colorStyle() {
                if (this.option.colors.length === 1) {
                    return {
                        'background-color': this.option.colors[0]
                    }
                } else if (this.option.colors.length === 2) {
                    const c1 = this.option.colors[0],
                        c2 = this.option.colors[1]
                    return {
                        'background-image': 'linear-gradient(to right, '+c1+', '+c1+' 50%, '+c2+' 50%, '+c2+')',
                    }
                } else if (this.option.colorImg) {
                    return {
                        'background-image': 'url('+this.option.colorImg+')',
                        'background-size': 'cover'
                    }
                }
                return {}
            },
            darkMode() {
                const color = this.option.colors[0]
                if(color) {
                    const rgb = colorsTransform.hexToRGB(color)
                    const hsl = colorsTransform.rgbToHsl(rgb[0], rgb[1], rgb[2])
                    return hsl[2] < 0.5
                }
                return false
            }
        },
        methods: {
            /**
             * place text bubble in the left of spot if it's placed too close to the right of the window
             */
            onMouseOver() {
                let rightLimit = window.innerWidth - 100
                const sidebarAncestor = this.$el.closest('.sidebar-body')
                if(sidebarAncestor) {
                    rightLimit = sidebarAncestor.getBoundingClientRect().right - 100
                }
                this.textLeft = (this.$el.getBoundingClientRect().right > rightLimit)
            }
        }
    }
</script>
