export default {
    or: 'ou',
    zoommore: 'Agrandir',
    zoomless: 'Réduire',
    undo: 'Annuler',
    redo: 'Rétablir',
    share: 'Partager',
    help: 'Instructions',
    save: 'Enregistrer',
    apply: 'Appliquer',
    delete: 'Supprimer',
    preview: 'Aperçu',
    download: 'Télécharger',
    stockAvailable: 'Couleur, quantité et stock disponible',
    update: 'Modifier',
    quantity: 'Quantité',
    colorsquantity: 'Couleurs & quantités',
    close: 'Fermer',
    duplicate: 'Dupliquer',
    regles: 'Règles',
    color: 'Couleur',
    edit: 'Éditer',
    crop: 'Recadrer',
    apiError: 'Une erreur s\'est produite, veuillez essayer à nouveau',
    validateSelection: 'Valider ma sélection',
    copyImage: 'Copier l\'image',
    imageCopied: 'Image copiée',
    sidebar: {
        title: 'Options de personnalisation'
    },
    cart: {
        validate: 'Valider ma personnalisation',
        validateWithout: 'Valider sans personnalisation',
        priceHt: 'Votre prix - € HT',
        totalHt: 'TOTAL HT',
        noShipping: 'hors frais de port',
        soit: 'Soit',
        unit: 'unité',
        details: 'détails',
        buy: 'Acheter'
    },
    texts: {
        title: 'Textes',
        add: 'Ajouter un nouveau texte libre'
    },
    marking: {
        spots: 'Emplacement & techniques | Emplacements & techniques',
        size: 'Marquage maxi',
        techniques: 'Technique disponible | Techniques disponibles',
        emplacementTechniques: 'Emplacement et technique de marquage | Techniques de marquage disponibles'
    },
    imageupload: {
        title: 'Logos',
        add: 'Ajouter un logo',
        addOpenSource: 'Ajouter un motif',
        extensionsBest: 'Formats privilégiés',
        extensionsAllowed: 'Formats autorisés',
        drop: 'Déposer vos visuels ici pour les ajouter',
        help: 'En utilisant cette image, je confirme avoir les droits pour son utilisation.',
        maxSize: 'Taille maximale'
    },
    pao: {
        title: 'Commentaires',
        subtitle: 'Quelque chose que vous n’arrivez pas à faire ? Besoin d’améliorer ou de modifier votre logo ?',
        yourCreation: 'Votre projet actuel',
        messageLabel: 'Comment pouvons-nous vous aider ?',
        messagePlaceholder: 'Comment pouvons-nous vous aider à améliorer votre projet ?',
        messageHelp: '<p><b>Téléchargez votre logo en fichier vectoriel .ai, .eps ou .pdf</b><br>pour accélérer le traitement de votre commande. 🙂</p>' +
            '<p><b>Nous vous enverrons un Bon A Tirer (BAT) par mail sous 24 à 48h pour validation.</b><br>Votre commande partira en production une fois le BAT validé 👍</p>',
        canDo: 'Ce que l’on peut faire !',
        cantDo: 'Ce que l’on ne peut pas faire !',
        canDoText: '- Ajuster votre logo\n' +
        '- Modifier les couleurs\n' +
        '- Supprimer des éléments',
        cantDoText: '- Améliorer les images en basse résolution\n' +
        '- Modifier la zone de marquage',
        send: 'Enregistrer mon message',
        ok: '<b>Merci !</b> votre message a été enregistré',
    },
    imagecrop: {
        title: 'Recadrer',
        subtitle: 'Utilisez cet outil pour masquer une zone de votre logo que vous ne souhaitez pas marquer !'
    },
    imageEdit: {
        title: 'Modifier mon logo',
        subtitle: 'Modifier les propriétés de votre logo pour bien le préparer au marquage !',
        menuTitle: 'Mes options',
        color: 'Couleur de marquage | Couleurs de marquage',
        invert: 'Inverser les couleurs',
        backgroundDelete: 'Détail du logo',
        reverse: 'Réinitialiser',
        instructions: 'Instructions',
        informations: 'Informations',
        informationsText: 'Après validation de votre commande, notre <b>équipe d\'infographistes</b> traitera votre logo pour correspondre au marquage choisit.<br> Vous recevrez par mail un <b>BON À TIRER</b> afin de valider définitivement votre marquage.'
    },
    library: {
        title: 'Bibliothèque images',
        subtitle: 'La bibliothèque de vos visuels & logos',
        menuTitle: 'Ma bibliothèque',
        drop: 'Déposer votre logo* ici',
        browse: 'Sélectionner votre logo *',
        menu: {
            add: 'Ajouter un logo',
            saved: 'Mes logos enregistrés',
            icons: 'Mes motifs'
        }
    },
    colorpicker: {
        library: 'Bibliothèque couleurs',
        searched: 'Couleurs recherchées',
        preset: 'Couleurs utilisées',
        disabled: 'Vous ne pouvez pas modifer la couleur pour un marquage laser ou gravure.'
    },
    techniques: {
        title: 'Techniques de marquage',
        subtitle: 'Techniques de marquage disponibles pour ce produit',
        menuTitle: 'Techniques de marquage',
        SERI: 'Sérigraphie',
        TADI: 'Tampographie',
        BRODE: 'Broderie'
    },
    sharePopup: {
        title: 'Partage',
        subtitle: 'Partagez votre personnalisation avec votre entourage',
        menuTitle: 'Partagez votre création',
        link: 'Envoyez le lien',
        social: 'Utilisez les réseaux sociaux',
        shareLink: 'Partagez ce lien avec qui vous voulez !',
        copy: 'Copier le lien',
        copied: 'Lien copié',
        socials: 'Utilisez les réseaux sociaux'
    },
    motifs: {
        title: 'Ajouter un motif',
        subtitle: 'Besoin d\'un coup de pouce pour créer votre visuel ? Utilisez notre banque d\'images !',
        menuTitle: 'Choisissez un thème',
        help: 'Sélectionnez jusqu\'à 3 images, elles seront directement insérées à votre visuel.'
    }
}
